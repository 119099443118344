import React from 'react';

import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { CloseButton } from './CloseButton';

export const Drawer = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
  height: '100%',
  width: '100%',
  boxShadow: shadows.fenceLeftLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceLeftDark,
  },
  '@sm': {
    maxWidth: 360,
  },
});

export const DrawerTitle = styled('h2', Body2, {
  fontWeight: fontWeights.medium,
  display: 'block',
  color: colors['gray-800'],
  [darkThemeSelector]: {
    color: colors.white,
  },
});

export const DrawerHeader = styled('div', {
  overflow: 'auto',
  whiteSpace: 'nowrap',
  hStack: '$8',
  height: '$56',
  paddingX: '$20',
  boxShadow: shadows.fenceBottomLight,
  flexShrink: 0,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceBottomDark,
  },
});

export const DrawerContent = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  padding: '$20',
  overflow: 'auto',
});

export const DrawerFooter = styled('div', {
  overflow: 'auto',
  whiteSpace: 'nowrap',
  hStack: '$8',
  height: '$56',
  paddingX: '$20',
  position: 'sticky',
  flexShrink: 0,
  display: 'flex',
  bottom: 0,
  boxShadow: 'none',
  '&:before': {
    position: 'absolute',
    top: 0,
    left: '$16',
    right: '$16',
    height: '$2',
    content: '',
    display: 'block',
    background: colors['gray-100'],
    borderRadius: '$2',
  },
});

export const DrawerControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

export interface CloseDrawerButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const CloseDrawerButton = (props: CloseDrawerButtonProps) => (
  <CloseButton type="button" {...props} aria-label={props['aria-label'] ?? 'Close drawer'} />
);
