import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../hooks/useControllerConfig';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { useRemoveServiceSetMutation } from './hooks';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssids/:id/remove',
});

export default function SSIDRemovePage() {
  const { id } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDRemovePage));
  const controller = useCurrentController();

  const configModel = useControllerConfig(controller);

  expectDefinedOrThrow(
    configModel,
    new ResourceNotFoundError(`Controller ${controller} not found`),
  );

  const serviceSetModel = configModel.getServiceSetByStableId(id);

  expectDefinedOrThrow(serviceSetModel);
  const navigate = useNavigate();

  const closeDrawer = useCloseDrawerCallback();

  const removeConfigMutation = useRemoveServiceSetMutation(controller, configModel, id, () => {
    closeDrawer();
  });

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Remove SSID</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the SSID "${serviceSetModel?.ssid}"?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button onClick={() => navigate(-1)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => removeConfigMutation.mutate()} variant="destructive">
            Remove SSID
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}
