import { MeterV2WirelessServiceSet } from '@meterup/config';

import type { FixMeLater } from '../../../../types/fixMeLater';
import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';

export const modelToFormData = (model: MeterV2WirelessServiceSet): ValidServiceSetData => ({
  ssid: model.ssid,
  shouldBroadcastSSID: !model.hidden,
  pskSchemeType: model.pskScheme.type as FixMeLater,
  pskValue: model.pskScheme.staticValue,
  pskRotationFrequency: model.pskScheme.rotationFrequency,
  vlan: model.json.network ?? '',
  is5GEnabled: model.bands.has('5 GHz'),
  is2GEnabled: model.bands.has('2.4 GHz'),
});

export const formDataToModel = (
  tagName: string,
  stableId: string,
  values: ValidServiceSetData,
): MeterV2WirelessServiceSet => MeterV2WirelessServiceSet.fromProps(tagName, stableId, values);
