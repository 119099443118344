import type { MeterControllerConfig, MeterV2WirelessServiceSet } from '@meterup/config';
import produce from 'immer';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { formDataToModel } from './form_data';

export const produceConfigWithNewServiceSet = (
  configModel: MeterControllerConfig,
  prevServiceSetModel: MeterV2WirelessServiceSet,
  nextServiceSetData: ValidServiceSetData,
) => {
  const nextServiceSet = formDataToModel(
    prevServiceSetModel.tagName,
    prevServiceSetModel.stableId,
    nextServiceSetData,
  );

  // TRICKY: Some service sets have additional JSON fields that are not part of the model. We need
  // to add the original JSON to the new service set model in order to preserve the fields that would
  // otherwise be lost in translation.
  const nextServiceSetModalWithJSON = produce(nextServiceSet, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft.json = prevServiceSetModel.json;
  });

  return produce(configModel, (draft) => {
    draft.upsertServiceSet(nextServiceSetModalWithJSON);
  });
};
