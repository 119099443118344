import * as z from 'zod';

export const LD_FEATURE_FLAGS = z.object({
  'meter-auth-ui': z.boolean(),
  'tablet-splash-screen': z.boolean(),
  'metrics-ui': z.boolean(),
  'cos-config': z.boolean(),
  'controller-events': z.boolean(), // Display the controller event logs
  'client-wired-wireless-filter-ui': z.boolean(), // Show or hide client wired/wireless filter UI
  'content-filtering-ui': z.boolean(), // Show or hide content filtering UI
});

export interface MeterLDFlags extends Partial<z.TypeOf<typeof LD_FEATURE_FLAGS>> {}

export const LOCAL_LD_FLAG_VALUES: MeterLDFlags = {
  'meter-auth-ui': false,
  'tablet-splash-screen': true,
  'metrics-ui': true,
  'cos-config': true,
  'controller-events': true,
  'client-wired-wireless-filter-ui': true,
  'content-filtering-ui': true,
};

export const PH_FEATURE_FLAGS = z.object({
  'static-client-config': z.boolean(),
});

export interface MeterPHFlags extends Partial<z.TypeOf<typeof PH_FEATURE_FLAGS>> {}

export const LOCAL_PH_FLAG_VALUES: MeterPHFlags = {
  'static-client-config': true,
};
