import { Tooltip as TitleTooltip } from '@meterup/common';
import { Badge, Body2, HStack, Icon, Small2, space, VStack } from '@meterup/metric';
import { fonts, fontWeights, styled } from '@meterup/metric/src/stitches.config';
import {
  AreaSeries,
  Axis,
  buildChartTheme,
  DataContext,
  LineSeries,
  Tooltip,
  XYChart,
} from '@visx/xychart';
import { flatten } from 'lodash';
import { DateTime } from 'luxon';
import React, { useContext, useMemo } from 'react';
import { useId } from 'react-aria';

import type { TimeSeriesData, TimeSeriesDataValue } from '../api/types';
import { colors, shadows } from '../stitches';
import { Box } from './Box';

const gradientStopColor = colors['brand-600'].toString();

const theme = buildChartTheme({
  svgLabelSmall: {
    fontFamily: fonts.sans.toString(),
    fontWeight: fontWeights.regular.toString(),
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: 0,
    fill: colors['gray-500'].toString(),
  },
  xAxisLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  xTickLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  yTickLineStyles: {
    stroke: colors['gray-300'].toString(),
  },
  colors: [colors['brand-500'].toString()],
  gridColor: colors['gray-100'].toString(),
  backgroundColor: colors.white.toString(),
  gridColorDark: colors['gray-700'].toString(),
  tickLength: 4,
});

const CheckeredBackground = () => {
  const id = useId();
  const {
    width = 0,
    height = 0,
    margin = { left: 0, right: 0, top: 0, bottom: 0 },
  } = useContext(DataContext);

  const backgroundWidth = width > 0 ? width - margin.left - margin.right : 0;

  return (
    <>
      <pattern id={id} x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
        <rect x="0" width="4" height="4" y="0" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="0" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
        <rect x="4" width="4" height="4" y="4" style={{ fill: `${colors.black}`, opacity: 0.02 }} />
        <rect x="0" width="4" height="4" y="4" style={{ fill: `${colors.white}`, opacity: 0.02 }} />
      </pattern>

      <rect
        x={margin.left}
        y={margin.top}
        width={backgroundWidth}
        height={height - margin.top - margin.bottom}
        fill={`url(#${id})`}
      />

      <line
        x1={margin.left}
        x2={width - margin.right}
        y1={height - margin.bottom}
        y2={height - margin.bottom}
        stroke={colors['gray-100'].toString()}
      />
    </>
  );
};

const SeriesLine = ({ data }: { data: TimeSeriesData }) => {
  const gradientId = useId();
  if (data.series_type === 'area') {
    return (
      <>
        <defs>
          <linearGradient
            id={gradientId}
            x1="50%"
            y1="100%"
            x2="50%"
            y2="0%"
            gradientUnits="objectBoundingBox"
          >
            <stop stopColor={gradientStopColor} stopOpacity="0" />
            <stop offset="0.4" stopColor={gradientStopColor} />
            <stop offset="1" stopColor={gradientStopColor} />
          </linearGradient>
        </defs>
        <AreaSeries
          dataKey={data.series_id}
          data={data.data}
          xAccessor={(d) => d.timestamp}
          yAccessor={(d) => d.value}
          curve={data.curve}
          lineProps={{ strokeWidth: 1.25 }}
          renderLine
          fillOpacity={0.2}
          fill={`url(#${gradientId})`}
        />
      </>
    );
  }

  return (
    <LineSeries
      dataKey={data.series_id}
      data={data.data}
      xAccessor={(d) => d.timestamp}
      yAccessor={(d) => d.value}
      curve={data.curve}
      strokeWidth={1.25}
    />
  );
};

const dateFormatter = (date: Date) => DateTime.fromJSDate(date).toFormat('MMM dd hh:mm a');

export interface TimeSeriesChartProps {
  title: string;
  titleBadge?: React.ReactNode;
  tooltipBody: React.ReactNode;
  series: TimeSeriesData[];
  valueFormatter: (value: number) => string;
  yTicks?: number[];
  isError: Boolean;
}

export const TimeSeriesChart = ({
  title,
  titleBadge,
  tooltipBody,
  series,
  valueFormatter,
  yTicks,
  isError,
}: TimeSeriesChartProps) => {
  const yDomain = useMemo((): number[] => {
    const min = Math.min(...series.map((val) => val.range.min));
    const max = Math.max(...series.map((val) => val.range.max));
    const delta = max - min;

    // If the delta is less than 1, we are most likely dealing with a percent. In this case, we just
    // want the graph to be drawn from 0% to 200%, so 100% is the center of the graph.
    if (delta < 1) {
      return [0, 2];
    }

    // This 10% pad can be adjusted if necessary, but it visually seems to be pretty acceptable.
    // For area series, it adds 10% to the top of the chart, and for line series, it seems to keep
    // the line relatively centered.
    const percent = 10;
    const pad = Math.max((delta / 100) * percent, 5);

    // The domain goes from 0 to the sum of max + min + pad. Including the min does not change area
    // series, they still get drawn from 0 to the max value + 10%. Including the min has a major
    // effect on the line series, as it is the logic that keeps the line in the center of the graph.
    return [0, max + min + pad];
  }, [series]);

  // The reason we have this logic, is because we will need to support multiple series in a single
  // API call in the future. Think when Throughput supports both RX + TX, or WAN Quality supports
  // multiple WANs.
  const renderStatusBadge = () => {
    if (isError) {
      return (
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon="crossCircle"
          size="small"
          variant="negative"
        >
          Error
        </Badge>
      );
    }

    const allValues = flatten(series.map((data) => data.data));

    if (allValues.length === 0) {
      return (
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon="minusCircle"
          size="small"
          variant="brand"
        >
          No data
        </Badge>
      );
    }

    return null;
  };

  const renderTitleTooltip = () => {
    const StyledIcon = styled(Icon, {
      marginTop: '$2',
    });

    return (
      <HStack spacing={space(6)}>
        <StyledIcon icon="information" size={12} />
        {tooltipBody}
      </HStack>
    );
  };

  return (
    <Box>
      <Box css={{ padding: '$8 $20' }}>
        <HStack spacing={space(8)} align="baseline">
          <TitleTooltip
            side="right"
            sideOffset={6}
            align="start"
            showHint
            content={renderTitleTooltip()}
          >
            <Body2 css={{ fontWeight: fontWeights.medium }}>{title}</Body2>
          </TitleTooltip>
          {titleBadge}
          {renderStatusBadge()}
        </HStack>
      </Box>
      <Box css={{ backgroundColor: colors['gray-50'], boxShadow: '$fenceTopBottomLight' }}>
        <XYChart
          height={200}
          xScale={{ type: 'time' }}
          yScale={{ type: 'linear', domain: yDomain }}
          margin={{ top: 0, left: 80, right: 20, bottom: 22 }}
          theme={theme}
        >
          <CheckeredBackground />
          <Axis
            orientation="left"
            numTicks={4}
            tickFormat={valueFormatter}
            strokeWidth={0}
            tickValues={yTicks}
          />
          <Axis orientation="bottom" strokeWidth={0} numTicks={3} tickFormat={dateFormatter} />

          {series.map((data) => (
            // TODO(apt, 2023-02-28): We will have to add logic to draw the series differently, so
            // they can be differentiated visually.
            <SeriesLine key={data.series_id} data={data} />
          ))}

          <Tooltip<TimeSeriesDataValue>
            snapTooltipToDatumX
            showVerticalCrosshair
            showSeriesGlyphs
            unstyled
            applyPositionStyle
            renderTooltip={({ tooltipData }) =>
              tooltipData?.nearestDatum ? (
                <Box
                  css={{
                    hStack: '$4',
                    background: colors.white,
                    boxShadow: shadows.overlayLight,
                    padding: '$4 $6',
                    borderRadius: '$6',
                  }}
                >
                  <VStack spacing={space(4)}>
                    <Small2 css={{ color: colors['gray-600'] }}>
                      {dateFormatter(tooltipData.nearestDatum.datum.timestamp)}
                    </Small2>
                    <HStack spacing={space(16)} align="center">
                      <Small2 css={{ fontWeight: fontWeights.medium }}>{title}</Small2>
                      <Badge size="small">
                        {valueFormatter(tooltipData.nearestDatum.datum.value)}
                      </Badge>
                    </HStack>
                  </VStack>
                </Box>
              ) : null
            }
          />
        </XYChart>
      </Box>
    </Box>
  );
};
