import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { Drawer, DrawerContent, DrawerControls, DrawerHeader, DrawerTitle } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchControllerSSID } from '../../../../api/api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { NetworkJoinWidget } from '../../../../components/NetworkJoinWidget';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssid/:ssidName',
});

export default function LegacySSIDDetailsPage() {
  const controller = useCurrentController();
  const { ssidName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacySSIDDetailsPage),
  );

  const ssid = useQuery(
    ['controller_ssids', controller, ssidName],
    () => fetchControllerSSID(controller, ssidName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(ssid, new ResourceNotFoundError(`SSID not found`));

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>SSID</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <NetworkJoinWidget
          ssid={ssid}
          defaultOpen="always"
          shouldAlwaysShowJoinInstructionsButton
        />
      </DrawerContent>
    </Drawer>
  );
}
