import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  space,
  VStack,
} from '@meterup/metric';
import React from 'react';

import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';

export const ConfirmNetworkChangesDrawer = ({ onCancel }: { onCancel: () => void }) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Configure SSID</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Alert
        icon="information"
        variant="alternative"
        heading="Changing network settings"
        copy={
          <VStack spacing={space(8)}>
            <div>
              Changing network wireless settings will restart the network. All clients will lose
              connectivity for approximately 30 seconds.
            </div>
            <div>Please confirm you would like to apply these changes now.</div>
          </VStack>
        }
      />
    </DrawerContent>
    <DrawerFooter>
      <DrawerControls>
        <Button type="button" onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </DrawerControls>
    </DrawerFooter>
  </Drawer>
);
