import type { ManufacturerIconName } from '@meterup/metric';
import { getManufacturerIconName } from '@meterup/common';
import { isAfter, isValid } from 'date-fns';
import oui from 'oui';

import type { ClientData } from '../api/types';

export type ConnectionStatus = 'online' | 'offline' | 'wired';

export const isWired = (c: ClientData) => Number(c.signal) === 0;

export const isWireless = (c: ClientData) => Number(c.signal) !== 0;

export const clientNameOrNull = (c: ClientData) => c.alias || (c.name !== '*' ? c.name : null);

export const isLastSeenKnown = (c: ClientData) => {
  if (c.last_seen) {
    const date = new Date(c.last_seen);
    return isValid(date) && isAfter(date, new Date(2010, 1, 1));
  }

  return false;
};

export const isOnline = (c: ClientData): boolean => {
  if (c.last_seen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.last_seen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const getClientConnectionStatus = (client: ClientData): ConnectionStatus => {
  if (isWireless(client)) {
    return isOnline(client) ? 'online' : 'offline';
  }

  return 'wired';
};

export const manufacturerIconName = (client: ClientData): ManufacturerIconName =>
  getManufacturerIconName(oui(client.mac_address)?.toLowerCase() ?? '');
