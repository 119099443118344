import type { SVGAttributes } from 'react';
import { IconSVG } from 'atto-svgs';
import React from 'react';

import type { ColorProps } from '../../utilities/shared/Color';
import { styled } from '../../stitches.config';
import { colorCSS } from '../../utilities/shared/Color';
import { space, SpacingValue } from '../../utilities/shared/sizes';

export const IconSizes = SpacingValue;

export type IconSize = number;

export const Icons = {
  accessControl: IconSVG.AccessControl,
  accessPoint: IconSVG.AccessPoint,
  area: IconSVG.Area,
  arrowDown: IconSVG.ArrowDown,
  arrowLeft: IconSVG.ArrowLeft,
  arrowRight: IconSVG.ArrowRight,
  arrowRotate: IconSVG.ArrowRotate,
  arrowsDiagonal: IconSVG.ArrowsDiagonal,
  arrowsMove: IconSVG.ArrowsMove,
  arrowsVertical: IconSVG.ArrowsVertical,
  arrowUp: IconSVG.ArrowUp,
  attention: IconSVG.Attention,
  bell: IconSVG.Bell,
  bellFilled: IconSVG.BellFilled,
  bookmark: IconSVG.Bookmark,
  bookmarkFilled: IconSVG.BookmarkFilled,
  calendar: IconSVG.Calendar,
  changelog: IconSVG.Changelog,
  channel: IconSVG.Channel,
  chat: IconSVG.Chat,
  checkmark: IconSVG.Checkmark,
  checkmarkCircle: IconSVG.CheckmarkCircle,
  chevronDown: IconSVG.ChevronDown,
  chevronLeft: IconSVG.ChevronLeft,
  chevronRight: IconSVG.ChevronRight,
  chevronsVertical: IconSVG.ChevronsVertical,
  chevronUp: IconSVG.ChevronUp,
  client: IconSVG.Client,
  clock: IconSVG.Clock,
  cog: IconSVG.Cog,
  company: IconSVG.Company,
  connection: IconSVG.Connection,
  contentFilter: IconSVG.ContentFilter,
  controller: IconSVG.Controller,
  copy: IconSVG.Copy,
  cross: IconSVG.Cross,
  crossCircle: IconSVG.CrossCircle,
  delete: IconSVG.Delete,
  device: IconSVG.Device,
  document: IconSVG.Document,
  download: IconSVG.Download,
  drawerClose: IconSVG.DrawerClose,
  drawerOpen: IconSVG.DrawerOpen,
  email: IconSVG.Email,
  eyeClosed: IconSVG.EyeClosed,
  eyeOpen: IconSVG.EyeOpen,
  filter: IconSVG.Filter,
  floorplan: IconSVG.Floorplan,
  fullscreen: IconSVG.Fullscreen,
  globe: IconSVG.Globe,
  home: IconSVG.Home,
  information: IconSVG.Information,
  internet: IconSVG.Internet,
  location: IconSVG.Location,
  log: IconSVG.Log,
  megaphone: IconSVG.Megaphone,
  menu: IconSVG.Menu,
  minus: IconSVG.Minus,
  minusCircle: IconSVG.MinusCircle,
  noise: IconSVG.Noise,
  onboarding: IconSVG.Onboarding,
  overflowHorizontal: IconSVG.OverflowHorizontal,
  overflowVertical: IconSVG.OverflowVertical,
  padlock: IconSVG.Padlock,
  paneLeft: IconSVG.PaneLeft,
  paneRight: IconSVG.PaneRight,
  pencil: IconSVG.Pencil,
  phone: IconSVG.Phone,
  placeholder: IconSVG.Placeholder,
  plus: IconSVG.Plus,
  plusCircle: IconSVG.PlusCircle,
  portforwarding: IconSVG.Portforwarding,
  power: IconSVG.Power,
  pulse: IconSVG.Pulse,
  qrcode: IconSVG.QRCode,
  question: IconSVG.Question,
  reporting: IconSVG.Reporting,
  search: IconSVG.Search,
  searchScoped: IconSVG.SearchScoped,
  secure: IconSVG.Secure,
  settings: IconSVG.Settings,
  share: IconSVG.Share,
  sort: IconSVG.Sort,
  speedHigh: IconSVG.SpeedHigh,
  speedLow: IconSVG.SpeedLow,
  speedMedium: IconSVG.SpeedMedium,
  ssid: IconSVG.SSID,
  switch: IconSVG.Switch,
  tag: IconSVG.Tag,
  trafficShaping: IconSVG.TrafficShaping,
  upload: IconSVG.Upload,
  user: IconSVG.User,
  version: IconSVG.Version,
  warning: IconSVG.Warning,
  width: IconSVG.Width,
  wifi: IconSVG.WiFi,
  wired: IconSVG.Wired,
  wrench: IconSVG.Wrench,
};

export type IconName = keyof typeof Icons;
export const IconNames = Object.keys(Icons) as IconName[];

export interface IconProps {
  /**
   * Set an HTML classname.
   */
  className?: string;
  /**
   * Accepts any color value from our [colorset](/?path=/docs/colors).
   */
  color?: ColorProps;
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set a size of the component.
   */
  size?: IconSize;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  color: `var(---iconColor, currentColor)`,
});

export const Icon: React.FunctionComponent<IconProps> = ({
  className,
  color,
  icon,
  size = space(16),
}) => {
  const NamedIcon = Icons[icon as IconName] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  const cssFromProps = colorCSS(color?.dark, color?.light);

  return (
    <BaseSVG as={NamedIcon} width={size} height={size} className={className} css={cssFromProps} />
  );
};

export default Icon;
