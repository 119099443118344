import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import { MeterV2WirelessServiceSet } from '@meterup/config';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../hooks/useControllerConfig';
import { useToggle } from '../../../../hooks/useToggle';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../utils/withZodSchema';
import { validServiceSetData } from '../../../../validations/validServiceSetData';
import { ConfirmNetworkChangesDrawer } from './ConfirmNetworkChangesDrawer';
import { modelToFormData } from './form_data';
import { useUpdateServiceSetMutation } from './hooks';
import { BandsField, PasswordField, SSIDField, VLANField } from './ssid_fields';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssids/create',
});

export default function SSIDCreatePage() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const model = useControllerConfig(controllerName);

  expectDefinedOrThrow(model, new ResourceNotFoundError(`Controller ${controllerName} not found`));

  const closeDrawer = useCloseDrawerCallback();

  const draftModel = useMemo(() => MeterV2WirelessServiceSet.createEmpty(), []);

  const navigate = useNavigate();

  const isConfirmingChanges = useToggle();

  const updateConfigMutation = useUpdateServiceSetMutation(
    controllerName,
    model,
    draftModel,
    () => {
      navigate(
        makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
          id: draftModel.stableId,
          controllerName,
          companyName,
        }),
      );
    },
  );

  return (
    <Formik<ValidServiceSetData>
      validate={withZodSchema(validServiceSetData)}
      initialValues={modelToFormData(draftModel)}
      onSubmit={(values) => {
        if (isConfirmingChanges.value) {
          updateConfigMutation.mutate(values);
        } else {
          isConfirmingChanges.toggleOn();
        }
      }}
    >
      <StyledForm>
        {isConfirmingChanges.value ? (
          <ConfirmNetworkChangesDrawer onCancel={isConfirmingChanges.toggleOff} />
        ) : (
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Create SSID</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <SSIDField />
              <PasswordField />
              <BandsField />
              <VLANField model={model} />
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button type="button" onClick={closeDrawer} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        )}
      </StyledForm>
    </Formik>
  );
}
