import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/AccessPointSummary.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/AddClient.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientRename.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientSummary.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/LegacySSIDDetails.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDCreate.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDEdit.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/SSIDRemove.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterEnable.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleCreate.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleEdit.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleRemove.page.tsx";

const routes = [
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",element: React.createElement(PagefilesImport3)},
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress/rename",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/controller/:controllerName/clients/add",element: React.createElement(PagefilesImport1)},
{path: "/org/:companyName/controller/:controllerName/content-filters/enable",element: React.createElement(PagefilesImport8)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/:id",element: React.createElement(PagefilesImport10)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/:id/remove",element: React.createElement(PagefilesImport11)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/create",element: React.createElement(PagefilesImport9)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",element: React.createElement(PagefilesImport0)},
{path: "/org/:companyName/controller/:controllerName/ssid/:ssidName",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/edit",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/remove",element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/controller/:controllerName/ssids/create",element: React.createElement(PagefilesImport5)},
];

export default routes;