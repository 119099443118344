import type { api } from '@meterup/proto';
import type { SortingState } from '@tanstack/react-table';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import {
  Badge,
  BodyMono2,
  Button,
  EmptyState,
  ManufacturerIcon,
  SmallMono2,
  TextInput,
} from '@meterup/metric';
import React from 'react';
import { useNavigate } from 'react-router';

import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isOnline,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../utils/clientLists';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { Box } from './Box';
import { PageControls, PageHeader, PageSection } from './Page/Page';
import { ShowWirelessInfoButton } from './ShowWirelessInfoButton';
import { AutoTable2 } from './Table/AutoTable2';
import { createColumnBuilder } from './Table/createColumnBuilder';
import { createFilterEnumeration } from './Table/createFilterEnumeration';

const builder = createColumnBuilder<api.UserClient>();

const columns = [
  builder.data((d) => `${getClientConnectionStatus(d)} ${isWireless(d) ? 'wireless' : 'wired'}`, {
    header: '',
    minSize: 40,
    maxSize: 40,
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isWireless(p.row) ? 'wifi' : 'wired'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((row) => `${row.alias} ${row.name}`, {
    header: 'Name',
    minSize: 120,
    size: 300,
    meta: {
      isLeading: true,
      sizingMode: 'truncate',
    },
    cell: (p) => (
      <Box css={{ hStack: '$8' }}>
        <ManufacturerIcon icon={manufacturerIconName(p.row)} size="small" />
        <Box css={{ truncate: true }}>{clientNameOrNull(p.row) ?? <BodyMono2>-</BodyMono2>}</Box>
      </Box>
    ),
  }),
  builder.data((row) => row.ip_address, {
    header: 'IP',
    meta: { sizingMode: 'fit-max', isCopyable: true },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((row) => row.mac_address, {
    header: 'MAC',
    meta: {
      sizingMode: 'fit-max',
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((d) => d.signal.toFixed(0), {
    header: 'Signal (dBm)',
    meta: {
      sizingMode: 'fit-min',
    },
    cell: (p) =>
      isWireless(p.row) ? (
        <Badge
          arrangement="leading-icon"
          variant={p.row.signal > -74 ? 'positive' : 'negative'}
          size="small"
          ends="pill"
        >
          {p.value}
        </Badge>
      ) : (
        <SmallMono2>-</SmallMono2>
      ),
  }),
];

const Filters = createFilterEnumeration<api.UserClient>(
  [
    {
      key: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'wireless',
      label: 'Wireless',
      predicate: isWireless,
    },
    {
      key: 'wired',
      label: 'Wired',
      predicate: isWired,
    },
  ],
  {
    urlKey: 'tab',
  },
);

const EmptyStates = ({
  clientCount,
  filteredClientCount,
  onResetFilters,
}: {
  clientCount: number;
  filteredClientCount: number;
  onResetFilters: () => void;
}) => {
  if (clientCount === 0) {
    return <EmptyState icon="client" heading="No clients on this network" />;
  }

  if (filteredClientCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button
            variant="tertiary"
            icon="minusCircle"
            arrangement="leading-icon"
            onClick={onResetFilters}
          >
            Reset filters
          </Button>
        }
      />
    );
  }

  return null;
};

export const ClientsList = ({
  clients,
  showTabSwitcher = true,
  showShowWirelessInfoButton = true,
}: {
  clients: api.UserClient[];
  showTabSwitcher?: boolean;
  showShowWirelessInfoButton?: boolean;
}) => {
  const params = Nav.useRegionParams('drawer', paths.drawers.ClientSummaryPage);

  const closeDrawer = useCloseDrawerCallback();
  const filteredClients = Filters.useCurrentPredicate(clients);
  const resetFilter = Filters.useResetCallback();
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const navigate = useNavigate();

  const { state } = useCommand();

  useRegisterCommands([
    state.nodeFactory.action({
      id: 'view-wireless-info',
      display: 'View wireless info',
      label: 'View wireless info',
      priority: Priority.Low,
      icon: 'qrcode',
      onSelect() {
        navigate(
          makeDrawerLink(window.location, paths.drawers.AddClientPage, {
            companyName,
            controllerName,
          }),
        );
      },
    }),
  ]);

  return (
    <>
      <PageHeader>
        {showTabSwitcher && <Filters.TabSwitcher data={clients} />}
        <PageControls>
          {showShowWirelessInfoButton && (
            <div style={{ width: 'fit-content' }}>
              <ShowWirelessInfoButton />
            </div>
          )}
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter clients"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      <PageSection>
        {filteredClients.length > 0 && (
          <AutoTable2
            columns={columns}
            data={filteredClients}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.ClientSummaryPage, {
                macAddress: row.mac_address,
                companyName,
                controllerName,
              })
            }
            isRowSelected={(row) => params?.macAddress === row.mac_address}
            onRowDeselect={closeDrawer}
          />
        )}
        <EmptyStates
          clientCount={clients.length}
          filteredClientCount={filteredClients.length}
          onResetFilters={resetFilter}
        />
      </PageSection>
    </>
  );
};
