import type { MeterControllerConfig } from '@meterup/config';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { PSKRotationFrequency, PSKSchemeType } from '@meterup/config';
import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CompositeField,
  FieldContainer,
  HStack,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  SmallMono2,
  space,
  TextInput,
  ToggleInput,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React from 'react';

import type { ValidServiceSetData } from '../../../../validations/validServiceSetData';
import { getSuggestedPassword } from '../../../../api/api';
import { CopyToClipboardButtonMinimal } from '../../../../components/CopyToClipboardButton';
import { createFieldProvider } from '../../../../components/FieldProvider';
import { FormikConditional } from '../../../../components/FormikConditional';
import { PasswordStrengthIndicator } from '../../../../components/PasswordStrengthIndicator';
import { logError } from '../../../../utils/logError';

const FieldProvider = createFieldProvider<ValidServiceSetData>();

export const SSIDField = () => (
  <FieldContainer>
    <FieldProvider name="ssid">
      <PrimaryField label="SSID" element={<TextInput />} />
    </FieldProvider>
    <FieldProvider name="shouldBroadcastSSID">
      <SecondaryField label="Broadcast SSID publicly" element={<ToggleInput />} />
    </FieldProvider>
  </FieldContainer>
);

export const PasswordField = ({ currentValue }: { currentValue?: string | null }) => {
  const formik = useFormikContext<ValidServiceSetData>();

  const suggestPassword = async () => {
    try {
      const password = await getSuggestedPassword(3);
      formik.setFieldValue('pskValue', password);
    } catch (e) {
      logError(e);
    }
  };

  const copyablePassword =
    formik.values.pskSchemeType === PSKSchemeType.Static
      ? formik.values.pskValue
      : currentValue ?? '';

  const suggestPasswordButton = (
    <Button
      type="button"
      icon="arrowRotate"
      arrangement="hidden-label"
      onClick={suggestPassword}
      variant="tertiary"
    >
      Suggest password
    </Button>
  );

  return (
    <PrimaryFieldComposite
      label="Password"
      fields={
        <HStack spacing={space(8)}>
          <FieldProvider name="pskSchemeType">
            <div style={{ flexShrink: 0 }}>
              <CompositeField
                label="Type"
                element={
                  <BasicSelect>
                    <BasicSelectItem value={PSKSchemeType.None}>None</BasicSelectItem>
                    <BasicSelectItem value={PSKSchemeType.Static}>Static</BasicSelectItem>
                    <BasicSelectItem value={PSKSchemeType.Rotating}>Rotating</BasicSelectItem>
                  </BasicSelect>
                }
              />
            </div>
          </FieldProvider>
          <FormikConditional<ValidServiceSetData>
            condition={(values) => values.pskSchemeType === PSKSchemeType.Static}
          >
            <FieldProvider name="pskValue">
              <div style={{ width: '100%' }}>
                <CompositeField label="Password" element={<TextInput />} />
              </div>
            </FieldProvider>
          </FormikConditional>
          <FormikConditional<ValidServiceSetData>
            condition={(values) => values.pskSchemeType === PSKSchemeType.Rotating}
          >
            <FieldProvider name="pskRotationFrequency">
              <CompositeField
                label="Rotation frequency"
                element={
                  <BasicSelect>
                    <BasicSelectItem value={PSKRotationFrequency.Never}>Never</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Daily}>Daily</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Weekly}>Weekly</BasicSelectItem>
                    <BasicSelectItem value={PSKRotationFrequency.Monthly}>Monthly</BasicSelectItem>
                  </BasicSelect>
                }
              />
            </FieldProvider>
          </FormikConditional>
        </HStack>
      }
      controls={
        <>
          <FormikConditional<ValidServiceSetData>
            condition={(v) => v.pskSchemeType === PSKSchemeType.Static}
          >
            <PasswordStrengthIndicator password={formik.values.pskValue ?? ''} />
            {suggestPasswordButton}
          </FormikConditional>
          <FormikConditional<ValidServiceSetData>
            condition={(v) => v.pskSchemeType !== PSKSchemeType.None}
          >
            {isDefinedAndNotEmpty(copyablePassword) && (
              <CopyToClipboardButtonMinimal text={copyablePassword} />
            )}
          </FormikConditional>
        </>
      }
      description={
        <FormikConditional<ValidServiceSetData>
          condition={(v) => v.pskSchemeType === PSKSchemeType.Rotating && !!currentValue}
        >
          <div>
            The current value is <SmallMono2 as="code">{currentValue}</SmallMono2>.
          </div>
        </FormikConditional>
      }
    />
  );
};

export const BandsField = () => (
  <FieldContainer>
    <ListItemHeader>
      <ListTitle>Bands</ListTitle>
    </ListItemHeader>
    <FieldProvider name="is5GEnabled">
      <SecondaryField label="5 GHz" element={<ToggleInput />} />
    </FieldProvider>
    <FieldProvider name="is2GEnabled">
      <SecondaryField label="2.4 GHz" element={<ToggleInput />} />
    </FieldProvider>
  </FieldContainer>
);

export const VLANField = ({ model }: { model: MeterControllerConfig }) => (
  <FieldProvider name="vlan">
    <PrimaryField
      label="VLAN"
      element={
        <BasicSelect>
          <BasicSelectItem value="" disabled>
            None
          </BasicSelectItem>
          {model.getCustomerFacingVLANs().map((vlan) => (
            <BasicSelectItem key={vlan.name} value={vlan.name}>
              {vlan.name}
            </BasicSelectItem>
          ))}
        </BasicSelect>
      }
    />
  </FieldProvider>
);
