import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/pages/App.layout.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/pages/CompanyRoot.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/pages/NotFound.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/pages/Providers.layout.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/pages/Root.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/pages/TabletJoinInstructions.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Network.layout.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Overview.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/pages/network/Sidebar.layout.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/pages/network/metrics/EventsList.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/ContentFilterList.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/ISPList.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/InternetAndWireless.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/dashboard/src/routes/pages/network/settings/SSIDList.page.tsx";
import PagefilesImport14 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointDetail.layout.tsx";
import PagefilesImport15 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointDetail.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/access_points/AccessPointList.page.tsx";
import PagefilesImport17 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/ClientDetail.layout.tsx";
import PagefilesImport18 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/ClientDetail.page.tsx";
import PagefilesImport19 from "/vercel/path0/apps/dashboard/src/routes/pages/network/hardware/clients/ClientsList.page.tsx";

const routes = [
{path: "*",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName",element: React.createElement(PagefilesImport1)},
{element: React.createElement(PagefilesImport3)},
{path: "/",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/controller/:controllerName/join/:ssid",element: React.createElement(PagefilesImport5)},
{children: [{children: [{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",children: [{index: true,element: React.createElement(PagefilesImport18)},],element: React.createElement(PagefilesImport17)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",children: [{index: true,element: React.createElement(PagefilesImport15)},],element: React.createElement(PagefilesImport14)},
{path: "/org/:companyName/controller/:controllerName",children: [{index: true,element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/controller/:controllerName/clients",element: React.createElement(PagefilesImport19)},
{path: "/org/:companyName/controller/:controllerName/content-filters",element: React.createElement(PagefilesImport10)},
{path: "/org/:companyName/controller/:controllerName/devices",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/controller/:controllerName/events",element: React.createElement(PagefilesImport9)},
{path: "/org/:companyName/controller/:controllerName/internet-wifi",element: React.createElement(PagefilesImport12)},
{path: "/org/:companyName/controller/:controllerName/isps",element: React.createElement(PagefilesImport11)},
{path: "/org/:companyName/controller/:controllerName/ssids",element: React.createElement(PagefilesImport13)},],element: React.createElement(PagefilesImport6)},],element: React.createElement(PagefilesImport8)},],element: React.createElement(PagefilesImport0)},
];

export default routes;